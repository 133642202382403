import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import { SlArrowLeft } from "react-icons/sl";

const CarouselButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pages = ["/about", "/services", "/contact", "/"];

  const handleNext = () => {
    const currentIndex = pages.indexOf(location.pathname);
    const nextIndex = (currentIndex + 1) % pages.length;
    const nextPage = pages[nextIndex];
    navigate(nextPage);
  };

  const handlePrevious = () => {
    const currentIndex = pages.indexOf(location.pathname);
    const previousIndex = (currentIndex - 1 + pages.length) % pages.length;
    const previousPage = pages[previousIndex];
    navigate(previousPage);
  };

  // const isServicesPage = location.pathname === "/services";
  // const isAboutPage = location.pathname === "/about";
  // const isContactPage = location.pathname === "/contact";
  // const isHomePage = location.pathname === "/";

  // const isPreviousDisabled = isHomePage ? true : false;
  // const isNextDisabled = isContactPage ? true : false;

  return (
    <div className="carousel-container">
      <div className="carousel-previous">
        <button
          className="btn carousel-btn "
          onClick={handlePrevious}
          // disabled={isPreviousDisabled}
        >
          <SlArrowLeft color="White" />
        </button>
      </div>
      <div className="carousel-next">
        <button
          className="btn carousel-btn"
          onClick={handleNext}
          // disabled={isNextDisabled}
        >
          <SlArrowRight color="White" />
        </button>
      </div>
    </div>
  );
};

export default CarouselButton;
