import React from "react";
import "./style.css";
import CarouselButton from "../../components/Button/CarouselButton";

const Services = () => {
  return (
    <div className="main-div">
      <div className="carousel-button-div">
        <CarouselButton />
      </div>

      <div className="about-images">
        <div className="about-heading">Our Services</div>
      </div>
      <div className="services-div">
        <div className="service-box">
          <div className="img-div">
            <img src={require("./images/web.png")} />
            <h4 className="heading">Web Development</h4>
            <p className="services-text">
              Transforming ideas into digital experiences through innovative web
              development solutions.
            </p>
          </div>
        </div>
        <div className="service-box">
          <div className="img-div">
            <img src={require("./images/mobile.png")} />
            <h4 className="heading">Mobile Development</h4>
            <p className="services-text">
              Expert mobile development services transforming ideas into
              seamless mobile experiences. iOS, Android, cross-platform
              solutions. Innovative, user-centric apps.
            </p>
          </div>
        </div>
        <div className="service-box">
          <div className="img-div">
            <img src={require("./images/ui.png")} />
            <h4 className="heading">Enterprise software</h4>
            <p className="services-text">
              Transform your business with powerful enterprise software
              solutions. Streamline operations, boost productivity, and drive
              growth. Experience efficiency like never before
            </p>
          </div>
        </div>
        <div className="service-box">
          <div className="img-div">
            <img src="https://cdn4.quintetsolutions.com/img/services/web.png" />
            <h4 className="heading">Contract Staffing</h4>
            <p className="services-text">
              Flexible workforce solutions for your business. Harness the power
              of contract staffing to meet your dynamic staffing needs with
              ease.
            </p>
          </div>
        </div>
      </div>
      <div className="silver-contnr">
        <h3> Why us ?</h3>
        <p className="service-text">
          Anticipating your business needs for future expansion, we ponder the
          requirements with all our senses, research with all our caliber, and
          learn with all our intellect to devise the perfect solution. We
          believe your growth is our business, and we go the extra mile to make
          sure the solution we propose takes your business to the next level and
          more. So why not us?
        </p>
        <div className="process">
          <div className="step">
            <div className="bullet">01</div>
            <h4 className="heading-step">Expertise</h4>
            <p className="p-step">
              Our team comprises highly skilled professionals with a diverse
              range of expertise, ensuring that we have the knowledge and skills
              to tackle any software development challenge.
            </p>
          </div>
          <div className="step two">
            <div className="bullet">02</div>
            <h4 className="heading-step">Customization</h4>
            <p className="p-step">
              We believe in tailoring our solutions to your unique requirements,
              providing personalized experiences that align with your business
              goals.
            </p>
          </div>
          <div className="step three">
            <div className="bullet">03</div>
            <h4 className="heading-step"> Quality</h4>
            <p className="p-step">
              We have a strong commitment to delivering high-quality solutions
              that are thoroughly tested and built to stand the test of time.
            </p>
          </div>
          <div className="step four">
            <div className="bullet">04</div>
            <h4 className="heading-step">Reliability</h4>
            <p className="p-step">
              Our focus on timely delivery and open communication ensures that
              you can rely on us as a trusted partner throughout your project's
              lifecycle.
            </p>
          </div>
          <div className="step five">
            <div className="bullet">05</div>
            <h4 className="heading-step">Innovation</h4>
            <p className="p-step">
              We stay up-to-date with the latest industry trends and
              technologies, bringing innovative ideas to the table and helping
              you stay ahead in the competitive landscape.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
