import React from "react";
import CarouselButton from "../../components/Button/CarouselButton";
import "./style.css";

const About = () => {
  return (
    <div>
      <div className="carousel-button-div">
        <CarouselButton />
      </div>
      <div className="about-container">
        <div className="about-image">
          <div className="about-heading">
            Crafting Next-Generation <br></br> Software Experiences for a
            Dynamic World
          </div>
        </div>
        <div>
          <div className="about-div">About Behelam</div>
          <div className="about-text-div">
            <p className="about-text">
              At Behelam, we are a dynamic and innovative software development
              firm dedicated to turning your ideas into powerful solutions. With
              our passion for technology and commitment to excellence, we
              provide a range of cutting-edge products and services tailored to
              meet your unique business needs.
            </p>

            <div class="section-heading">Our Products</div>
            <p className="about-text">
              We specialize in creating bespoke software solutions that are
              customized to address your specific requirements. Whether you need
              a web application, mobile app, or enterprise software, our team of
              experienced developers utilizes the latest technologies and
              industry best practices to deliver robust and scalable solutions.
              We believe in harnessing the power of technology to drive your
              business forward, enhance efficiency, and unlock new
              possibilities.
            </p>

            <div class="section-heading">Our Services</div>
            <p className="about-text">
              In addition to our product offerings, we also provide contract
              staffing services to support your organization's growth and
              augment your technical capabilities. We understand that finding
              skilled professionals can be a challenge, so we offer flexible
              staffing solutions to bridge the talent gap. Our team of expert
              developers, designers, and project managers can seamlessly
              integrate with your existing teams or work independently to
              deliver outstanding results within your desired timeframes.
            </p>
            <div class="footer">
              <p className="footer-text">
                Partner with Behelam and experience the power of bespoke
                development and contract staffing services that drive your
                business growth and success. Together, let's transform your
                vision into reality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
