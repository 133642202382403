import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import "./style.css";
const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerWrap">
        <div className="socialMedia">
          <div className="socialMediaWrap">
            <div className="socialLogo">Behelam</div>
            <div className="websiteRights">
              {" "}
              Behelam © {new Date().getFullYear()} All rights reserved.{" "}
            </div>
            <div className="socialIcons">
              <a
                className="socialIconLink"
                href="/"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </a>
              <a
                className="socialIconLink"
                href="/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </a>
              <a
                className="socialIconLink"
                href="/"
                target="_blank"
                aria-label="Youtube"
              >
                <FaYoutube />
              </a>
              <a
                className="socialIconLink"
                href="/"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </a>
              <a
                className="socialIconLink"
                href="https://www.linkedin.com/company/nexonetcis-solution-and-services-pvt-ltd/"
                target="_blank"
                aria-label="Linkedin"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
