import React from "react";
import "./style.css";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import CarouselButton from "../../components/Button/CarouselButton";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="hero-container">
      <div className="home-carousel">
        <CarouselButton />
      </div>

      <h1>Behelam</h1>
      <p>Crafting Customer-Centric Solutions</p>
      <div className="hero-btns">
        {/* <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            onClick={navigate("services")}
          >
            GET STARTED
          </Button> */}
      </div>
    </div>
  );
};

export default Home;
