import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa"; // Importing Font Awesome hamburger icon
import "./style.css";
import LogoSvg from "../../assets/Your_paragraph_text__2_-removebg-preview (1).png";
const Navbar = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <div className="navbar-container">
        <div className="navbar-div">
          <div className="nav-image">
            <img src={LogoSvg} alt="nav-logo" className="nav-logo" />
          </div>
          <div className="hamburger-icon" onClick={handleMenuToggle}>
            <FaBars />
          </div>
        </div>
        <div className={`nav-item ${isMenuOpen ? "open" : ""}`}>
          <div
            className="items"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </div>
          <div
            className="items"
            onClick={() => {
              navigate("/services");
            }}
          >
            Services
          </div>
          {/* <div className="items">Careers</div> */}
          <div
            className="items"
            onClick={() => {
              navigate("/about");
            }}
          >
            About us
          </div>
          <div
            className="items"
            onClick={() => {
              navigate("/contact");
            }}
          >
            Contact us
          </div>
        </div>
        <div
          className="nav-item-quote"
          onClick={() => {
            navigate("/contact");
          }}
        >
          Get a free Quote
        </div>
      </div>
    </div>
  );
};

export default Navbar;
